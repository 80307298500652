import { useRouter } from 'next/router'
import React, { createContext, useContext, useLayoutEffect, useState } from 'react'
import { removeCookies } from '../helper/removeCookies'

import cookie from 'js-cookie'

interface IContextProps {
  isLoggedIn?: boolean
  login?: (auth: string) => void
  logout?: () => void
  setEmail?: (email: string) => void
  getEmail?: () => string
  getDocument?: () => string
  getToken?: () => string
}

const AuthContext = createContext<IContextProps>({})

export function AuthWrapper({ children }) {
  const router = useRouter()

  const [state, setState] = useState({
    signed: false
  })

  useLayoutEffect(() => {
    if (cookie.get('auth')) {
      setState({ signed: true })
    }
  }, [])

  const onLogin = (auth: string) => {
    removeCookies()

    cookie.set('auth', auth, {
      expires: 1
    })

    setState({ signed: true })

    if (cookie.get('auth')) {
      router.push('/')
    }
  }

  const onLogout = () => {
    removeCookies()

    router.push('/login')
  }

  const getEmail = (): string => {
    const { email } = JSON.parse(cookie.get('auth'))

    return email
  }

  const getDocument = (): string => {
    const { document } = JSON.parse(cookie.get('auth'))

    return document
  }

  const setEmail = (email: string): void => {
    const auth = JSON.parse(cookie.get('auth'))

    auth.email = email

    JSON.stringify(auth)

    cookie.set('auth', JSON.stringify(auth))
  }

  const getToken = (): string => {
    const { token } = JSON.parse(cookie.get('auth'))

    return token
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: state.signed,
        login: (auth: string) => onLogin(auth),
        logout: () => onLogout(),
        setEmail: (email: string) => setEmail(email),
        getEmail: () => getEmail(),
        getDocument: () => getDocument(),
        getToken: () => getToken()
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}
