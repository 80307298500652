import React, { useEffect, useState } from 'react'
import Loading from '@/presentation/components/container/loading'
import { AuthWrapper } from '../presentation/contexts/AuthContext'
import { ContractWrapper } from '@/presentation/contexts/ContractContext'
import { useRouter } from 'next/router'
import type { AppProps } from 'next/app'
import 'tailwindcss/tailwind.css'
import '../presentation/styles/globals.css'
import '../presentation/styles/style.css'
import '../presentation/components/micro/navbar/style.css'
import '../presentation/components/micro/toggleSwitch/style.css'
import '../presentation/components/container/login/steps/style.css'
import '../presentation/components/container/success/style.css'
import '../presentation/components/container/graphic/style.css'
import TagManager from 'react-gtm-module'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [loading, setLoading] = useState('')

  const [showPwaWarning, setShowPwaWarning] = useState(false)

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-TP8NBV9' })
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      setLoading('Aguarde, estamos nos ligando com você!')
    })
    router.events.on('routeChangeComplete', () => {
      setLoading(undefined)
    })
    router.events.on('routeChangeError', () => {
      setLoading('Opa! Parece que algo deu errado. Por favor, recarregue a página.')
    })
  }, [router])

  useEffect(() => {
    const windowVar: any = window
    const isIos = () => {
      const userAgent = windowVar.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    }
    const isInStandaloneMode = () => 'standalone' in windowVar.navigator && windowVar.navigator.standalone
    if (isIos() && !isInStandaloneMode()) {
      setShowPwaWarning(true)
    }
  }, [])

  return (
    <AuthWrapper>
      {showPwaWarning && (
        <div className="fixed w-full bottom-0 z-50">
          <div style={{ background: '#f2f8ff' }} className="p-2">
            <div className="flex flex-row items-center">
              <p className="text-xs">
                <strong className="text-primary">Minha Ligue no seu iPhone 📱</strong> <br />
                Clique em <img src="/share.png" className="inline-block" width={15} /> no Safari e depois Adicionar à
                Tela de Início.
              </p>
            </div>
          </div>
        </div>
      )}
      <ContractWrapper>{loading ? <Loading text={loading} /> : <Component {...pageProps} />}</ContractWrapper>
    </AuthWrapper>
  )
}

export default MyApp
