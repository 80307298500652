import React from 'react'

type TypographyTypes = 'h1' | 'h2' | 'paragraph'

export interface iTypographyProps {
  type?: TypographyTypes
  className?: string
}

const Typography: React.FC<iTypographyProps> = ({ type, className, ...rest }) => {
  const defaultClasses = `font-head ${className || ''} `

  const getTag = (): any => {
    switch (type) {
      case 'h1':
        return <h1 className={defaultClasses + 'text-2xl'}>{rest.children}</h1>
      case 'h2':
        return <h2 className={defaultClasses + 'text-lg'}>{rest.children}</h2>
      case 'paragraph':
        return <p className={defaultClasses + 'text-sm'}>{rest.children}</p>
      default:
        return <span className={defaultClasses}>{rest.children}</span>
    }
  }

  return getTag()
}

export default Typography
