import React from 'react'
import Typography from '../../micro/typography'

interface LoadingProps {
  text: string
}

const Loading: React.FC<LoadingProps> = ({ text }) => {
  return (
    <div className="fixed top-0 left-0 flex flex-col items-center justify-center bg-white w-full h-full transition-all">
      <img src="/ligue-loading.gif" alt="Carregando..." className="w-30" />
      <Typography className="text-primary">{text}</Typography>
    </div> 
  )
}

export default Loading
