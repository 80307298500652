import React, { createContext, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/dist/client/router'
import { Contract } from '@/domain/Contract'
import cookie from 'js-cookie'

interface IContextProps {
  listContracts?: Contract[]
  active?: string
  setContract?: (number: string) => void
  setAllContracts?: (contracts: Contract[]) => void
}

const ContractContext = createContext<IContextProps>({})

export function ContractWrapper({ children }) {
  const [allContracts, setAllContracts] = useState<Contract[]>()
  const [active, setActive] = useState<string>(cookie.get('contract'))
  const router = useRouter()

  const onSetContract = (contract: string): void => {
    cookie.set('contract', contract.toString())
    setActive(contract)
    router.reload()
  }

  useEffect(() => {
    const getCookieContracts = cookie.get('allContracts')

    if (getCookieContracts) {
      const transformCookiesToJson = JSON.parse(getCookieContracts)

      setAllContracts(transformCookiesToJson)
    }
  }, [])

  useEffect(() => {
    if (!cookie.get('contract') && allContracts) {
      const chooseFirstContract = allContracts[0].code

      cookie.set('contract', chooseFirstContract)
      setActive(chooseFirstContract)
    }
  }, [allContracts])

  const onSetAllContracts = (contracts: Contract[]): void => {
    cookie.set('allContracts', JSON.stringify(contracts))
    setAllContracts(contracts)
  }

  return (
    <ContractContext.Provider
      value={{
        listContracts: allContracts,
        active: active,
        setContract: (number: string) => onSetContract(number),
        setAllContracts: (contracts: Contract[]) => onSetAllContracts(contracts)
      }}
    >
      {children}
    </ContractContext.Provider>
  )
}

export function useContractContext() {
  return useContext(ContractContext)
}
